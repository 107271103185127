import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { Container, Row, Col } from 'react-grid-system';
import Head from 'components/head';
import Logo from 'components/logo';
import Title from 'components/title';
import Block from 'components/block';
import MarkdownWrapper from 'components/markdownwrapper';

const centerItems = {
  textAlign: 'center',
};

const Services = ({ data }) => (
  <Layout>
    <Head pageTitle={data.servicesJson.title} />
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <div style={centerItems}>
            <Logo />
            <Title as="h1" size="large">
              {data.servicesJson.title}
            </Title>
          </div>
        </Col>
      </Row>
    </Container>
    <div style={{ height: '5vh' }} />
    <Block>
      <div className="block hasBg">
        <Container>
          <Row>
            <Col col={12} sm={12}>
              <MarkdownWrapper>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.servicesJson.content.childMarkdownRemark.html,
                  }}
                />
              </MarkdownWrapper>
            </Col>
          </Row>
        </Container>
      </div>
    </Block>
  </Layout>
);

Services.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Services;

export const query = graphql`
  query ServicesQuery {
    servicesJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
